import {
  APP_INITIALIZER,
  ErrorHandler,
  enableProdMode,
  isDevMode,
} from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import {
  RouteReuseStrategy,
  provideRouter,
  withPreloading,
  PreloadAllModules,
  Router,
} from '@angular/router';
import {
  IonicRouteStrategy,
  provideIonicAngular,
} from '@ionic/angular/standalone';
import { provideAnimations } from '@angular/platform-browser/animations';
import { routes } from './app/app.routes';
import { AppComponent } from './app/app.component';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { HttpService } from '@http/http.service';
import { LoggingService } from '@utils/logging.service';
import { ErrorHandlerService } from '@http/error-handler.service';
import { authInterceptor } from '@interceptors/auth-interceptor.service';
import { provideServiceWorker } from '@angular/service-worker';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { provideAuth, getAuth } from '@angular/fire/auth';

import * as Sentry from '@sentry/angular';
import { TraceService, createErrorHandler } from '@sentry/angular';
import { environment } from '@env/environment';
import { register } from 'swiper/element/bundle';

Sentry.init({
  dsn: environment.sentry.dsn,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false, // You can set this to false to opt out of masking all text in transactions,
      blockAllMedia: false,
      mask: ['.covent-mask'],
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/devapp\.coventapi\.com/,
    /^https:\/\/staging\.coventapi\.com/,
    /^https:\/\/covent\.app/,
  ],
  // Session Replay
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  enabled: !isDevMode(),
});

// Swiper
register();

if (environment.production) {
  enableProdMode();
}

if (environment.production) {
  window.console.log = () => {};
}

bootstrapApplication(AppComponent, {
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    provideIonicAngular({
      mode: 'ios',
      navAnimation: undefined,
      rippleEffect: false,
      scrollAssist: false
    }),
    provideRouter(routes, withPreloading(PreloadAllModules)),
    provideAnimations(),
    provideHttpClient(withInterceptors([authInterceptor])),
    HttpService,
    LoggingService,
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
    {
      provide: ErrorHandler,
      useValue: createErrorHandler(),
    },
    {
      provide: TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [TraceService],
      multi: true,
    },
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
  ],
}).then((ref) => {
  /* if (window['ngRef']) {
    window['ngRef'].destroy();
  }
  window['ngRef'] = ref; */
}).catch(err => console.error(err));
