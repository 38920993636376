import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { UserService } from '@services/user.service';

export const superAdminGuard: CanActivateFn = (route, state) => {
  const userService = inject(UserService);
  const router = inject(Router);

  if (userService.isSuperAdmin()) {
    return true;
  }

  // Redirect to home if not super admin
  return router.parseUrl('/home');
};
