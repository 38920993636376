import { computed, inject, Injectable, signal } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { COUNTRY_CITY_DATA } from '@components/event-search/event-search.helpers';
import { LoadingService } from '@controllers/loading.service';
import { ErrorHandlerService } from '@http/error-handler.service';
import { HttpService } from '@http/http.service';
import { PaginatedResponse, IEvent } from '@interfaces/events.interface';
import { Observable, Subject, tap } from 'rxjs';
import {
  PersonaManagerView,
  PersonaSearchRequest,
  SearchProfileView,
  SponsorPersonaCreateRequest,
  SponsorPersonaCreateResponse,
} from 'src/app/interfaces/persona.interface';

export type personaCreateFormKeys =
  | 'name'
  | 'job_titles'
  | 'target_audience'
  | 'country'
  | 'industry'
  | 'sponsor_company';

@Injectable({
  providedIn: 'root',
})
export class PersonaService {
  readonly maxPersonas = 3
  private readonly loaderService = inject(LoadingService);
  private readonly httpService = inject(HttpService);
  private readonly errorService = inject(ErrorHandlerService);
  private readonly subject = new Subject<PersonaManagerView>();
  readonly parameters = signal(0);
  readonly maxParameters = signal(20);
  readonly personaRarity = signal(0);
  readonly formComplete = signal(false);
  readonly newEventsLoaded = signal(false);
  readonly searchEventCount = signal<number>(0);
  readonly loadingEventCount = signal<boolean>(false);
  readonly viewPersona = signal<boolean>(false);
  readonly personas = signal<SearchProfileView[]>([]);

  readonly personaType = computed(() => {
    if (this.searchEventCount() > 45) {
      return {
        tagLine: "You're all set!",
        label: 'generic',
        class: 'bg-tertiary-600',
        text: "Your persona is currently Generic, which means it's broad and easy to find events for. Try adding more parameters to make it more targeted and impactful!",
        tooltip: `Your persona is currently Generic, which means it's broad and easy to find events for. Try adding more parameters to make it more targeted and impactful!`,
      };
    } else if (this.searchEventCount() >= 30) {
      return {
        tagLine: "You're all set!",
        label: 'niche',
        class: 'bg-tertiary-700',
        text: 'Your persona is Niche—great work! You’re starting to define a focused audience. Add a few more parameters to fine-tune and discover even more aligned events.',
        tooltip:
          'Your persona is niche, meaning it’s focused and tailored to specific interests. This makes event recommendations more relevant but slightly harder to find. Consider broadening or refining your criteria!',
      };
    } else if (this.searchEventCount() >= 15) {
      return {
        tagLine: "You're all set!",
        label: 'rare',
        class: 'bg-tertiary-800',
        text: 'Impressive! Your persona is Rare, which means you’ve honed in on a specific and valuable audience. Keep an eye out for exclusive events that match this profile!',
        tooltip:
          'Your persona is rare, making it highly specialized. While events matching your criteria exist, they might require deeper searching. Try adjusting your parameters to increase discoverability!',
      };
    } else {
      return {
        tagLine: "We're working on it!",
        label: 'extremely rare',
        class: 'bg-secondary-700',
        text: 'Wow, your persona is Extremely Rare—that means highly exclusive opportunities! We’re working on surfacing the best matches, so check back soon.',
        tooltip:
          'Your persona is extremely rare, making it highly unique but difficult to find events for. Consider loosening some constraints to uncover more opportunities while keeping it meaningful!',
      };
    }
  });

  create(request: SponsorPersonaCreateRequest) {
    return this.httpService.put<SponsorPersonaCreateResponse>(
      'search_profile',
      request
    );
  }

  toggleActive(uid: string, active: boolean) {
    return this.httpService.patch('search_profile/' + uid, { active });
  }

  personaManagerView() {
    return this.httpService.get<PersonaManagerView>(`search_profile/all`);
  }

  setCountryCityData(locations: string[]) {
    const country: string[] = [],
      city: string[] = [];
    locations?.forEach((location) =>
      COUNTRY_CITY_DATA.hasOwnProperty(location)
        ? country.push(location)
        : city.push(location)
    );
    return { country, city };
  }

  handleGetPersonaManagerView(): Observable<PersonaManagerView> {
    this.loaderService.showLoading();
    this.personaManagerView().subscribe({
      next: (result) => {
        this.subject.next(result);
        this.personas.set(result.search_profiles);
      },
      error: (e) => {
        this.errorService.handleError(e);
        this.loaderService.hideLoading();
      },
      complete: () => this.loaderService.hideLoading(),
    });
    return this.subject.asObservable();
  }

  getEventCount(
    payload: any,
    bypass: boolean
  ): Observable<PaginatedResponse<IEvent[]>> {
    return this.httpService.post(
      `event/atlas_search?offset=0&limit=1&bypass_airtable=${bypass}`,
      payload
    );
  }

  handleGetEventCount(payload: PersonaSearchRequest, bypass: boolean) {
    this.loadingEventCount.set(true);
    this.getEventCount(payload, bypass).subscribe({
      next: (events) =>
        this.searchEventCount.set(events.pagination.total_results),
      error: (error) => {
        this.loadingEventCount.set(false);
        this.errorService.handleError(error);
      },
      complete: () => this.loadingEventCount.set(false),
    });
  }
}

export const personaManagerResolver: ResolveFn<PersonaManagerView> = () => {
  return inject(PersonaService).handleGetPersonaManagerView();
};
