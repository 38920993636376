import { IChatMessageResponse, IChatRoomBase } from '@interfaces/chatroombase.interface';
import { IEvent } from '@interfaces/events.interface';
import { IEventOrganizer } from '@interfaces/events.org.interface';
import { ISponsor } from '@interfaces/sponsors.interface';
import Dexie, { Table } from 'dexie';
import { ChatMessageResponse, ChatMessageResponses } from './models/chatmessage.model';
import { ChatRoomBase, ChatRoomBases } from './models/chatroombase.model';
import { Event, Events } from './models/events.model';
import { EventOrganizer, EventOrganizers } from './models/events.org.model';
import { Sponsor, Sponsors } from './models/sponsors.model';
import { User } from './models/user.model';

export class CoVentDB extends Dexie {
  user!: Table<User, string>;
  events!: Table<Events, string>;
  sponsors!: Table<Sponsors, string>;
  eventOrgs!: Table<EventOrganizers, string>;
  chatRooms!: Table<ChatRoomBases, string>;
  chatMessages!: Table<ChatMessageResponses, string>;

  constructor() {
    super('CoVentDB');
    this.version(1).stores({
      user: "++id",
      events: "++id",
      sponsors: "++id",
      eventOrgs: "++id",
      chatRooms: "++id, uid",
      chatMessages: "++id, chat_room_uid",
    });
  }

  // User
  saveUser(data: User) {
    return coventDB.user.put(data);
  }

  updateUser(key: string, data: User) {
    return coventDB.user.update(key, data);
  }

  // Events
  saveEvents(data: IEvent[], searchData: { searchName: string, uid: string, query: string }) {
    const events: Events = {
      events: data as Event[],
      date: new Date(),
      name: searchData.searchName,
      search_profile_uid: searchData.uid,
      query: searchData.query
    }
    return coventDB.events.put(events);
  }

  saveSponsors(data: ISponsor[], searchData: { searchName: string, uid: string, query: string, total_results?: number }) {
    const sponsors: Sponsors = {
      sponsors: data as Sponsor[],
      date: new Date(),
      name: searchData.searchName,
      search_profile_uid: searchData.uid,
      query: searchData.query,
      total_results: searchData.total_results
    }
    return coventDB.sponsors.put(sponsors);
  }

  saveEventOrgs(data: IEventOrganizer[], searchData: { searchName: string, uid: string, query: string }) {
    const eventOrgs: EventOrganizers = {
      eventOrgs: data as EventOrganizer[],
      date: new Date()
    }
    return coventDB.eventOrgs.put(eventOrgs);
  }

  updateEvents(key: string, data: IEvent[]) {
    const events: any = {
      events: data as Event[],
      date: new Date()
    }
    return coventDB.events.update(key, events);
  }

  updateSponsors(key: string, data: ISponsor[]) {
    const sponsors: any = {
      sponsors: data as Sponsor[],
      date: new Date()
    }
    return coventDB.sponsors.update(key, sponsors);
  }

  updateEventOrgs(key: string, data: IEventOrganizer[]) {
    const eventOrgs: any = {
      eventOrgs: data as EventOrganizer[],
      date: new Date()
    }
    return coventDB.eventOrgs.update(key, eventOrgs);
  }

  saveChatRooms(data: IChatRoomBase[], searchData: { searchName: string, uid: string, query: string }) {
    const chatRoomBases: ChatRoomBases = {
      chatRoomBaseList: data as ChatRoomBase[],
      date: new Date()
    }
    return coventDB.chatRooms.put(chatRoomBases);
  }
  updateChatRooms(key: string, data: IChatRoomBase[]) {
    const chatRoomBases: any = {
      chatRoomBaseList: data as ChatRoomBase[],
      date: new Date()
    }
    return coventDB.chatRooms.update(key, chatRoomBases);
  }

  saveChatMessages(data: IChatMessageResponse[], chatRoomUID: string) {
    const chatMessages: ChatMessageResponses = {
      chatMessageList: data as ChatMessageResponse[],
      date: new Date(),
      chat_room_uid: chatRoomUID
    }
    return coventDB.chatMessages.put(chatMessages);
  }
  updateChatMessages(key: string, data: IChatMessageResponse[], chatRoomUID: string) {
    const chatMessages: any = {
      chatMessageList: data as ChatMessageResponse[],
      date: new Date(),
      chat_room_uid: chatRoomUID
    }
    return coventDB.chatMessages.update(key, chatMessages);
  }

}

export const coventDB = new CoVentDB();