import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(
    private platform: Platform
  ) { }

  /* public isNativeAppOrPWA() {
    return window.matchMedia('(display-mode: standalone)').matches || Capacitor.isNativePlatform();
  } */

  public isNativeApp() {
    return this.platform.is('capacitor');
  }

  public getPlatForm(): string {
    let platform: string = 'web';
    if(this.platform.is('mobile') && this.platform.is('android') && this.platform.is('capacitor')) {
      platform = 'android';
    } else if(this.platform.is('mobile') && this.platform.is('ios') && this.platform.is('capacitor')) {
      platform = 'ios';
    }
    return platform;
  }
}
