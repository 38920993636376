import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { EventsService } from '@services/events.service';
import { UserService } from '@services/user.service';
import { catchError, map, of } from 'rxjs';

export const homeGuard: CanActivateFn = (route, state) => {
  const userService = inject(UserService);
  const router = inject(Router);

  if (userService.currentUserValue.user_role === 'organizer') {
    router.navigate(['/sponsor-search']);
    return false
  } else {
    if (!userService.isFreeUser()) {
      return true;
    } else {
      router.navigate(['/search']);
      return false;
    }
  }
};
