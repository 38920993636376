import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import MixPanel, { Mixpanel } from 'mixpanel-browser';
import { UserService } from './user.service';
import { IEvent } from '@interfaces/events.interface';

const mpToken = environment.mixpanelToken;

@Injectable({
  providedIn: 'root',
})
export class MixPanelService {
  public mixPanel!: Mixpanel;
  private blockedDomains: string[] = [
    'joincovent.com',
    'foundersfactory.co.uk',
  ];

  constructor(private userService: UserService) {}

  public init(): void {
      this.mixPanel = MixPanel.init(
        mpToken,
        { debug: false, track_pageview: true },
        'CoVent'
      );
  }

  private allowMixpanel(domain: string): boolean {
    if (
      environment.name === 'production' &&
      this.blockedDomains.includes(domain)
    ) {
      return false;
    } else {
      return true;
    }
  }

  bookmark(event: IEvent): void {
    const user = this.userService.currentUserValue;
    if (this.allowMixpanel(user.email.split('@')[1])) {
      this.trackEvent(
        `event_${event.bookmark_flag ? 'unbookmark' : 'bookmark'}`,
        {
          event_uid: event.uid,
          event_name: event.name,
          action: event.bookmark_flag ? 'unbookmark' : 'bookmark',
        }
      );
    }
  }

  public trackEvent(event: string, data: any = {}): void {
    data.user = this.userService.currentUserValue?.alias || 'anonymous';
    data.user_email = this.userService.currentUserValue?.email || 'anonymous';
    data.user_role =
      this.userService.currentUserValue?.user_role || 'anonymous';
    data.subscription_type =
      this.userService.currentUserValue?.subscription || 'anonymous';
    data.account_status =
      this.userService.currentUserValue?.account_status || 'anonymous';

    if (this.allowMixpanel(data.user_email.split('@')[1])) {
      this.mixPanel.track(event, data, (result: any) => {});
    }
  }

  public identifyUser(): void {
    const user = this.userService.currentUserValue;
    if (user && this.allowMixpanel(user.email.split('@')[1])) {
      this.mixPanel.identify();
      this.mixPanel.people.set({
        $name: `${user.first_name} ${user.last_name}`,
        $email: user.email,
        $role: user.user_role || 'unknown',
        subscription_type: user.subscription || 'free',
        $last_login: new Date(),
        ...user,
      });
    }
  }
}
