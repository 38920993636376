import { HttpParams } from '@angular/common/http';
import { inject, Injectable, Signal, signal } from '@angular/core';
import { ResolveFn } from '@angular/router';
import {
  IRecommendedEvent,
  IRecommendedSponsor,
} from '@dexie/interfaces/dashboard.interface';
import { ErrorHandlerService } from '@http/error-handler.service';
import { HttpService } from '@http/http.service';
import { catchError, of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { tap } from 'rxjs/internal/operators/tap';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  public recommendedSponsors: Signal<IRecommendedSponsor[]> = signal([]);
  public recommendedEvents: Signal<IRecommendedEvent[]> = signal([]);
  public eventUpdates: Signal<any[]> = signal([]);
  public sponsorUpdates: Signal<any[]> = signal([]);

  public showUpdatesWebpage = signal<boolean>(false);

  constructor(private httpService: HttpService) {}

  public gethomeData(): Observable<any> {
    console.log('gethomeData');
    return this.httpService.get('event/homepage').pipe(
      tap((data: any) => {
        this.recommendedEvents = signal(data?.event_feed);
        this.eventUpdates = signal(data?.event_updates);
      })
    );
  }

  // public getHomeSponsorsData(): Observable<any> {
  //   console.log('getHomeSponsorsData');
  //   return this.httpService.get('assets/data/home_sponsers_data.json', new HttpParams(), 'http://localhost:8100')
  //     .pipe(tap((data: any) => {
  //       this.recommendedSponsors = signal(data);
  //     }));
  // }
  public getHomeSponsorsData(): Observable<any> {
    console.log('getHomeSponsorsData');
    return this.httpService.get('company/homepage').pipe(
      tap((data: any) => {
        // Need to remove
        // console.log('getHomeSponsorsData', data);
        // if (data.sponsors) {
        //   data.sponsors.forEach((fee: any) => {
        //     fee.sponsor = fee.company;
        //     delete fee['company'];
        //   });
        // }
        this.recommendedSponsors = signal(data?.sponsors);
        this.sponsorUpdates = signal(data?.updates);
      })
    );
  }
}

export const homeDataResolver: ResolveFn<any> = () => {
  const errorService = inject(ErrorHandlerService);
  return inject(HomeService)
    .gethomeData()
    .pipe(
      catchError((error) => {
        errorService.handleError(error, {
          api_name: 'event/homepage',
          method: 'get',
          view: 'home_view',
          error: error.message,
        });
        return of();
      })
    );
};
