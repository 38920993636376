import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { UserService } from '@services/user.service';
import { map } from 'rxjs/internal/operators/map';
import { catchError } from 'rxjs/internal/operators/catchError';
import { of } from 'rxjs/internal/observable/of';
import { LoadingController } from '@ionic/angular';

const loadingController = new LoadingController();
let loading: HTMLIonLoadingElement;

export const authGuard: CanActivateFn = (route, state) => {
  const userService = inject(UserService);
  const router = inject(Router);
  return userService.isAuthenticated().pipe(
    map((user: any) => {
      if (user?.email && user.onboarded && user.user_role && user.account_status !== 'pending') {
        return true;
      } else if (user?.email && (!user.onboarded || !user.user_role || user.account_status === 'pending')) {
        router.navigate(['/account-setup']);
        return false;
      } else {
        router.navigate(['/signin'], { queryParams: { redirectUrl: state.url } });
        return false;
      }
    }),
    catchError((error) => {
      router.navigate(['/signin']);
      return of(false);
    })
  );
};

const showHideLoading = async (show: boolean, message: string) => {
  if (show) {
    loading = await loadingController.create({
      message: message,
      spinner: 'crescent',
      translucent: false,
      showBackdrop: true,
      cssClass: 'covent-loading',
    });
    await loading.present();
  } else if(loading) {
    console.log('showHideLoading', show, message);
    await loading.dismiss();
  }
};
