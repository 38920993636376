import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  inject,
  NgZone,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import {
  IonApp,
  IonContent,
  IonRouterOutlet,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonFooter,
  IonButtons,
  IonButton,
  IonModal,
} from '@ionic/angular/standalone';
import { MixPanelService } from '@services/mix-panel.service';
import { UserService } from '@services/user.service';
import { UtilsService } from '@utils/utils.service';
import { addIcons } from 'ionicons';
import {
  mailOutline,
  mailSharp,
  paperPlaneOutline,
  paperPlaneSharp,
  heartOutline,
  heartSharp,
  archiveOutline,
  archiveSharp,
  trashOutline,
  trashSharp,
  warningOutline,
  warningSharp,
  bookmarkOutline,
  bookmarkSharp,
} from 'ionicons/icons';
import { Subscription } from 'rxjs/internal/Subscription';
import { interval } from 'rxjs/internal/observable/interval';

@Component({
  selector: 'covent-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [
    IonModal,
    IonButton,
    IonButtons,
    CommonModule,
    IonApp,
    IonContent,
    IonRouterOutlet,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonFooter,
  ],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  private userService = inject(UserService);

  public isNativeApp: boolean = this.utilsService.isNativeApp();

  private isNewVersionAvailable: boolean = false;
  private updateIntervalSource = interval(15 * 60 * 1000);
  private updateIntervalSubscription!: Subscription;
  private updateModal: any;

  constructor(
    private router: Router,
    private utilsService: UtilsService,
    private swUpdate: SwUpdate,
    private zone: NgZone,
    private mixPanelService: MixPanelService
  ) {
    const role = localStorage.getItem('covent.user.role');
    addIcons({
      mailOutline,
      mailSharp,
      paperPlaneOutline,
      paperPlaneSharp,
      heartOutline,
      heartSharp,
      archiveOutline,
      archiveSharp,
      trashOutline,
      trashSharp,
      warningOutline,
      warningSharp,
      bookmarkOutline,
      bookmarkSharp,
    });
  }

  ngOnInit(): void {
    localStorage.getItem('covent.darkmode') === 'true' &&
      document.documentElement.classList.add('dark');
  }

  ngAfterViewInit(): void {
    this.mixPanelService.init();
    this.checkUpdate();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.mixPanelService.trackEvent('page_view', {
          page: this.router.url.toString(),
        });
      }
    });
  }

  public openExternalLink(url: string) {
    window.open(url, '_blank');
    return false;
  }

  private async checkUpdate(): Promise<void> {
    this.updateIntervalSubscription?.unsubscribe();
    if (!this.swUpdate.isEnabled) {
      return;
    }
    this.checkUpdateAvailable();
    this.zone.runOutsideAngular(() => {
      this.updateIntervalSubscription = this.updateIntervalSource.subscribe(
        async () => {
          this.checkUpdateAvailable();
        }
      );
    });
  }

  private async checkUpdateAvailable(): Promise<void> {
    try {
      this.isNewVersionAvailable = await this.swUpdate.checkForUpdate();
      // console.log(this.isNewVersionAvailable ? 'A new version is available.' : 'Already on the latest version.');
      if (this.isNewVersionAvailable) {
        this.promptOnUpdateAvailable();
      }
    } catch (error) {
      console.error('Failed to check for updates:', error);
      this.mixPanelService.trackEvent('update_error', {
        error: error,
      });
    }
  }

  private async promptOnUpdateAvailable(): Promise<void> {
    this.updateModal = document.getElementById('updateCheckerModal');
    if (this.updateModal) {
      await this.updateModal.present();
    }
  }

  public applyUpdate(): void {
    this.swUpdate
      .activateUpdate()
      .then(() => document.location.reload())
      .catch((error: any) => {
        console.error('Failed to apply updates:', error);
        if (this.updateModal) {
          this.updateModal.dismiss();
        }
      });
  }

  ngOnDestroy(): void {
    this.updateIntervalSubscription?.unsubscribe();
  }
}
