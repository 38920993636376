import { inject, Injectable } from '@angular/core';
import { Auth, getRedirectResult, GoogleAuthProvider, signInWithPopup, signInWithRedirect } from '@angular/fire/auth';
import { HttpService } from '@http/http.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  public firebaseAuth = inject(Auth);

  constructor(private httpService: HttpService) {
    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser') ?? '{}'));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  login(email: string): Observable<any> {
    return this.httpService.post('auth/login', { email });
  }

  validateOtp(payload: any): Observable<any> {
    return this.httpService.post('auth/login_verification', payload).pipe(
      tap((data: any) => {
        if (data?.access_token) {
          localStorage.setItem('covent.user.token_data', JSON.stringify(data));
        }
      })
    );
  }

  public getToken(): string {
    return localStorage.getItem('covent.user.token_data') ? JSON.parse(localStorage.getItem('covent.user.token_data') ?? '{}').access_token : '';
  }

  public async loginWithGoogle(): Promise<any> {
    return new Promise((resolve, reject) => {
      signInWithPopup(this.firebaseAuth, new GoogleAuthProvider()).then((result: any) => {
        if (result?.user?.accessToken) {
          localStorage.setItem('covent.user.token_data', JSON.stringify({access_token: result.user.accessToken }));
          localStorage.setItem('covent.user.social_data', JSON.stringify({user_name: result.user.displayName }));
        }
        resolve(result);
      });
    });
    /* signInWithRedirect(this.firebaseAuth, new GoogleAuthProvider()).then((result) => {
      console.log('Google Sign-in successful', result);
      getRedirectResult(this.firebaseAuth).then((result: any) => {
        console.log(result);
        if (result.user) {
          console.log('User signed in with the redirect result:', result.user);
        }
       });
    }); */
  }

  validateSocialLogin(payload: any): Observable<any> {
    return this.httpService.post('auth/social_login_verification', payload).pipe(
      tap((data: any) => {
        if (data?.access_token) {
          localStorage.setItem('covent.user.token_data', JSON.stringify(data));
        }
      })
    );
  }

  logout(): void {
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }
}