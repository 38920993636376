import { inject, Injectable, Signal, signal } from '@angular/core';
import { coventDB } from '@dexie/database';
import { Sponsor, Sponsors } from '@dexie/models/sponsors.model';
import { User } from '@dexie/models/user.model';
import { HttpService } from '@http/http.service';
import {
  ISponsor,
  SponsorDetailResponse,
  SponsorEditRequest,
  SponsorshipAtlasSearchResponse,
} from '@interfaces/sponsors.interface';
import { Observable } from 'rxjs/internal/Observable';
import { tap } from 'rxjs/internal/operators/tap';
import { PaginatedResponse } from '@interfaces/events.interface';
import { SearchPaginationInfo } from 'src/app/interfaces/pagination';
import { ResolveFn } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { MixPanelService } from './mix-panel.service';
import { ErrorHandlerService } from '@http/error-handler.service';

@Injectable({
  providedIn: 'root',
})
export class SponsorsService {
  public searchedSponsors: Signal<Sponsors[]> = signal([]);
  public sponsors = signal<ISponsor[]>([]);
  public savedSponsors = signal<ISponsor[]>([]);
  public selectedSponsor = signal<ISponsor | undefined>(undefined);
  public selectedSponsorType = signal<string>('sponsor');
  public sponsorHistoryId: Signal<any> = signal(-1);
  public sponsorHistoryName: Signal<string> = signal('');
  public total_results = signal<number | undefined>(undefined);
  private readonly mixPanel = inject(MixPanelService);

  public showSponsorDetail = signal(false);
  public expandSponsorDetail = signal(false);
  public initializeSponsorsDetail = signal(false);

  constructor(private httpService: HttpService) {}

  public async getLocalData(): Promise<Sponsors[]> {
    // const allSponsors: any[] = await coventDB.sponsors?.toArray() ?? [];
    const allSponsors: any[] = (await coventDB.sponsors?.toArray()) ?? [];
    allSponsors.sort(
      (a: Sponsors, b: Sponsors) =>
        new Date(b.date).getTime() - new Date(a.date).getTime()
    );
    this.searchedSponsors = signal(allSponsors);
    if (this.searchedSponsors().length) {
      this.sponsors = signal(allSponsors[0].sponsors);
      this.sponsorHistoryId = signal(allSponsors[0].id);
      this.sponsorHistoryName = signal(allSponsors[0].name);
      this.total_results.set(allSponsors[0].total_results);
    }
    return allSponsors;
  }

  public saveLocalData(
    sponsor: ISponsor[],
    searchData: {
      searchName: string;
      uid: string;
      query: string;
      total_results?: number;
    }
  ): void {
    coventDB.saveSponsors(sponsor, searchData);
    this.getLocalData();
  }

  public async getDataById(id: string): Promise<ISponsor[]> {
    const sponsors = await coventDB.sponsors.where('id').equals(id).toArray();
    this.sponsorHistoryId = signal(id);
    this.sponsorHistoryName = signal(sponsors[0].name);
    return sponsors[0].sponsors as ISponsor[];
  }

  public saveSponsor(event: ISponsor): Observable<any> {
    return this.httpService
      .post(`company/bookmark/${event.uid}`, {
        name: event.search_profile_name,
        uid: event.search_profile_uid,
        match_info: event.match_info,
      })
      .pipe(
        tap((sponsorObject: any) => {
          if (this.selectedSponsor()) {
            this.selectedSponsor.set({
              ...this.selectedSponsor(),
              ...sponsorObject,
            });
          }
          this.updateSponsor(event.uid, sponsorObject);
        })
      );
  }

  public likeDislikeSponsor(
    uid: string,
    liked_flag: boolean | null
  ): Observable<SponsorshipAtlasSearchResponse> {
    return this.httpService.post(`sponsorship/rec_reaction/${uid}`, { liked_flag });
    //   .pipe(
    //     tap((sponsorObject: any) => {
    //       sponsorObject.liked_flag = likedFlag;
    //       if (this.selectedSponsor()) {
    //         this.selectedSponsor.set({
    //           ...this.selectedSponsor(),
    //           ...sponsorObject,
    //         });
    //       }
    //       this.updateSponsor(sponsor.uid, sponsorObject);
    //     })
    //   );
  }

  private async updateSponsor(
    sponsorUid: string,
    sponsorObject: any
  ): Promise<void> {
    this.sponsors.update((sponsors: ISponsor[]) => {
      sponsors.forEach((sponsor: ISponsor) => {
        if (sponsor.uid === sponsorUid) {
          sponsor.bookmark_flag = sponsorObject.bookmark_flag;
          sponsor.liked_flag = sponsorObject.liked_flag;
        }
      });
      return [...sponsors];
    });
    this.savedSponsors.update((sponsors: ISponsor[]) => {
      sponsors.forEach((sponsor: ISponsor) => {
        if (sponsor.uid === sponsorUid) {
          sponsor.bookmark_flag = sponsorObject.bookmark_flag;
          sponsor.liked_flag = sponsorObject.liked_flag;
        }
      });
      return [...sponsors];
    });
    coventDB.updateSponsors(this.sponsorHistoryId(), this.sponsors());
  }

  public getSavedSponsors(): Observable<any> {
    return this.httpService.get('company/bookmark/all').pipe(
      tap((sponsors: any) => {
        this.savedSponsors = signal(sponsors);
      })
    );
  }

  public getSponsorFromSlug(slug: string): Observable<any> {
    return this.httpService.get('sponsor/slug/' + slug);
  }

  public getCompanyById(id: string): Observable<any> {
    return this.httpService.get('company/' + id);
  }

  public getFreeSponsors(): Observable<SponsorshipAtlasSearchResponse[]> {
    return this.httpService.get('sponsorship/free/sponsorships');
  }

  public atlasSearch(
    payload?: any,
    pagination: SearchPaginationInfo = {
      offset: 0,
      limit: 30,
    }
  ) {
    this.mixPanel.trackEvent('sponsor_search', payload);
    return this.httpService.post<
      PaginatedResponse<SponsorshipAtlasSearchResponse[]>
    >(
      `sponsorship/atlas_search?offset=${pagination?.offset}&limit=${pagination?.limit}`,
      {
        payload,
      }
    );
  }

  getAllSponsorships() {
    return this.httpService.get<SponsorshipAtlasSearchResponse[]>(
      `sponsorship/all/sponsorships`
    );
  }

  public getSponsorship(uid: string) {
    this.mixPanel.trackEvent('sponsor_view', { sponsorship_uid: uid });
    return this.httpService.get<SponsorDetailResponse>(`sponsorship/${uid}`);
  }

  getAllBookMarkedSponsorships() {
    return this.httpService.get<SponsorshipAtlasSearchResponse[]>(
      'sponsorship/bookmark/all'
    );
  }

  bookmarkSponsorship(sponsorship: SponsorDetailResponse) {
    this.mixPanel.trackEvent('sponsor_bookmark', {
      sponsorship_name: sponsorship.owner_name,
      company: sponsorship.company_name,
    });
    return this.httpService.post<SponsorDetailResponse>(
      `sponsorship/bookmark/${sponsorship.uid}`,
      {
        sponsorship_uid: sponsorship.uid,
      }
    );
  }

  editSponsorship(uid: string, payload: SponsorEditRequest) {
    return this.httpService.patch(`sponsorship/${uid}`, payload);
  }
}

export const getAllSponsorShips: ResolveFn<
  SponsorshipAtlasSearchResponse[]
> = () => {
  const sponsorsService = inject(SponsorsService);

  return sponsorsService.getAllSponsorships().pipe(
    catchError((error) => {
      console.error('Error fetching sponsorships:', error);
      return of([]);
    })
  );
};

export const allBookmarkedSponsorshipsResolver: ResolveFn<
  SponsorshipAtlasSearchResponse[]
> = () => {
  const sponsorsService = inject(SponsorsService);
  return sponsorsService.getAllBookMarkedSponsorships().pipe(
    catchError((error) => {
      console.error('Error fetching sponsorships:', error);
      return of([]);
    })
  );
};

export const getSponsorsResolver: ResolveFn<
  SponsorshipAtlasSearchResponse[]
> = () => {
  const errorService = inject(ErrorHandlerService);
  return inject(SponsorsService)
    .getAllSponsorships()
    .pipe(
      catchError((error) => {
        errorService.handleError(error);
        return of([]);
      })
    );
};

export const getSponsorResolver: ResolveFn<SponsorDetailResponse> = (
  route,
  state
) => {
  console.log('resolving to the edit page');
  const errorService = inject(ErrorHandlerService);
  const sponsorshipUid = route.params['id'];
  return inject(SponsorsService)
    .getSponsorship(sponsorshipUid)
    .pipe(
      catchError((error) => {
        errorService.handleError(error);
        return of();
      })
    );
};
