import { computed, inject, Injectable, signal } from '@angular/core';
import { coventDB } from '@dexie/database';
import { IUser } from '@dexie/interfaces/user.interface';
import { User } from '@dexie/models/user.model';
import { HttpService } from '@http/http.service';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { from } from 'rxjs/internal/observable/from';
import { tap } from 'rxjs/internal/operators/tap';
import { jwtDecode } from 'jwt-decode';
import { ResolveFn, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private currentUserSubject: BehaviorSubject<any>;
  private currentUser: Observable<any>;
  public canViewHome = signal<boolean>(false);
  private router = inject(Router);

  isUpgradeModalOpen = signal<boolean>(false);
  isPersonaUpgradeModalOpen = signal<boolean>(false);
  isUpgradeScheduleModalOpen = signal<boolean>(false);

  constructor(private httpService: HttpService) {
    this.currentUserSubject = new BehaviorSubject<any>('');
    this.currentUser = this.currentUserSubject.asObservable();
  }

  private async intializeUser(userData: any): Promise<void> {
    this.currentUserSubject = new BehaviorSubject<any>(userData);
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public readonly isFreeUser = computed(
    () =>
      this.currentUserValue.subscription === 'free_monthly' ||
      this.currentUserValue.subscription === 'free_yearly'
  );

  public readonly isSponsor = computed(
    () => this.currentUserValue.user_role === 'sponsor'
  );
  public readonly isPremium = computed(() =>
    this.currentUserValue.subscription.includes('premium')
  );
  public readonly isAdmin = computed(
    () => this.currentUserValue.user_type === 'admin'
  );
  public readonly isSuperAdmin = computed(
    () => this.currentUserValue.user_type === 'super_admin'
  );

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  public getUser(): Observable<any> {
    return from(this.getLocalData());
  }

  public getUserApi(): Observable<any> {
    return this.httpService.get('user/me').pipe(
      tap((user: any) => {
        if (user?.email) {
          this.saveLocalData(user);
          this.currentUserSubject.next(user);
        }
      })
    );
  }

  public updateUser(payload: any): Observable<any> {
    return this.httpService.patch('user/me', payload).pipe(
      tap((user: any) => {
        if (user?.email) {
          this.saveLocalData(user);
          this.currentUserSubject.next(user);
        }
      })
    );
  }

  public async getLocalData(): Promise<IUser> {
    const userData = await coventDB.user.limit(1).toArray();
    if (userData.length) {
      this.intializeUser(userData[0]);
      localStorage.setItem('covent.user.role', userData[0].user_role);
      return userData[0];
    } else {
      const userTokenData = JSON.parse(
        localStorage.getItem('covent.user.token_data') ?? '""'
      );
      if (userTokenData) {
        const decoded: any = jwtDecode(userTokenData.access_token);
        if (decoded?.subject?.email) {
          return { email: decoded.subject.email } as IUser;
        } else {
          return {} as IUser;
        }
      } else {
        return {} as IUser;
      }
    }
  }

  private saveLocalData(user: IUser): void {
    coventDB.user.clear();
    // user.user_role = 'organizer';
    coventDB.saveUser(user);
    localStorage.setItem('covent.user.role', user.user_role);
  }

  isAuthenticated(): Observable<boolean> {
    return this.getUser();
  }

  getUserObs(): Observable<boolean> {
    return this.getUserApi();
  }

  public getDcodedJwtToken(token: string): any {
    return jwtDecode(token);
  }

  public async logout(): Promise<void> {
    await coventDB.user.clear();
    localStorage.clear();
    this.router.navigate(['/signin']);
    this.currentUserSubject.next(null);
  }
}

export const getUserResolver: ResolveFn<IUser> = () => {
  return inject(UserService).getUserApi();
};
