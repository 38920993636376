import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoggingService {

  logError(error: any) {
    // Send error to server or logging infrastructure
    console.error('LoggingService: ', error);
  }
}