import { Injectable, computed, inject, signal } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { LoadingService } from '@controllers/loading.service';
import { ErrorHandlerService } from '@http/error-handler.service';
import { Attendee, IEvent, JobChart } from '@interfaces/events.interface';
import { EventsService } from '@services/events.service';
import { UserService } from '@services/user.service';
import { catchError, finalize, tap } from 'rxjs';
import {
  JOB_FUNCTIONS,
  JOB_FUNCTION_COLORS,
  JOB_BAR_COLORS_GRAYSCALE,
  JOB_LEVELS,
  JOB_LEVEL_COLORS,
} from 'src/app/event-detail/job-functions';

type ChartKey =
  | 'sponsor_industry_chart'
  | 'sponsor_size_chart'
  | 'job_function_chart'
  | 'job_level_chart';

@Injectable({
  providedIn: 'root',
})
export class EventDetailService {
  public readonly userService = inject(UserService);
  public readonly eventsService = inject(EventsService);
  public readonly errorService = inject(ErrorHandlerService);

  public readonly jobFunctions = JOB_FUNCTIONS;
  public readonly jobfunctionColors = JOB_FUNCTION_COLORS;
  public readonly jobBarColorsGrayscale = JOB_BAR_COLORS_GRAYSCALE;
  public readonly jobLevels = JOB_LEVELS;
  public readonly jobLevelColors = JOB_LEVEL_COLORS;

  readonly _eventData = signal<IEvent | null>(null);
  public readonly _attendeeList = signal<Attendee[]>([]);
  public readonly _selectedAttendeeFilterTab = signal<string>('Total');
  public readonly _registeredAttendees = signal<any[]>([]);
  public readonly _forecastedAttendees = signal<any[]>([]);
  public readonly _totalAttendeeList = signal<any[]>([]);
  public readonly _uniqueAttendees = signal(new Set<string>());
  public readonly _sponsorList = signal<any[]>([]);
  public readonly _shortSponsorList = signal<any[]>([]);
  public readonly eventOwner = signal(false);

  get eventData() {
    return this._eventData();
  }

  get attendeeList(): Attendee[] {
    return this._attendeeList();
  }

  get registeredAttendees() {
    return this._registeredAttendees();
  }

  get forecastedAttendees() {
    return this._forecastedAttendees();
  }

  get totalAttendeeList() {
    return this._totalAttendeeList();
  }

  get uniqueAttendees() {
    return this._uniqueAttendees();
  }

  get selectedAttendeeFilterTab() {
    return this._selectedAttendeeFilterTab();
  }

  get shortSponsorList() {
    return this._shortSponsorList();
  }

  get sponsorList() {
    return this._sponsorList();
  }

  constructor() {}

  readonly proGuard = computed(() => this.userService.isFreeUser() || !this.eventOwner())

  resetSignals(): void {
    this._eventData.set(null);
    this._attendeeList.set([]);
    this._selectedAttendeeFilterTab.set('Total');
    this._registeredAttendees.set([]);
    this._forecastedAttendees.set([]);
    this._totalAttendeeList.set([]);
    this._uniqueAttendees.set(new Set<string>());
    this._sponsorList.set([]);
    this._shortSponsorList.set([]);
  }

  updateEventData(newData: any): void {
    this._eventData.set(newData);
  }

  setAttendees(limitItems: boolean = false): void {
    this._uniqueAttendees.set(new Set<string>());
    const isFreeUser = this.userService.isFreeUser();
    if (this.eventData?.standard_info?.speakers) {
      for (const attendee of this.eventData?.standard_info.speakers!) {
        attendee.role = 'Speaker';
        attendee.status = 'Registered';
        attendee.locked = isFreeUser;
        attendee.speaker = 'Yes';
        this.uniqueAttendees.add(JSON.stringify(attendee));
      }
    }

    if (this.eventData?.standard_info?.attendees) {
      for (const attendee of this.eventData.standard_info.attendees) {
        attendee.role = 'Attendee';
        attendee.status = 'Forecasted';
        attendee.locked = this.userService.isFreeUser();
        this.uniqueAttendees.add(JSON.stringify(attendee));
      }
    }
    this._attendeeList.set(
      Array.from(this.uniqueAttendees).map((attendee: string) =>
        JSON.parse(attendee)
      )
    );

    this._registeredAttendees.set(
      this._attendeeList().filter(
        (attendee: any) => attendee.status === 'Registered'
      )
    );

    this._forecastedAttendees.set(
      this._attendeeList().filter(
        (attendee: any) => attendee.status === 'Forecasted'
      )
    );

    this._totalAttendeeList.set([...this._attendeeList()]);
    if (limitItems) {
      this._totalAttendeeList.set(this._totalAttendeeList().slice(0, 4));
    }

    // Update job charts
    if (this.eventData?.standard_info) {
      const updatedEventData = {
        ...this.eventData,
        standard_info: {
          ...this.eventData.standard_info,
          job_function_chart:
            this.eventData?.standard_info?.job_function_chart ?? [],
          job_level_chart: this.eventData?.standard_info?.job_level_chart ?? [],
          sponsor_industry_chart:
            this.eventData?.standard_info?.sponsor_industry_chart ?? [],
          sponsor_size_chart:
            this.eventData?.standard_info?.sponsor_size_chart ?? [],
        },
      } as Required<IEvent>; // Ensures TypeScript treats this as fully defined

      if (updatedEventData.standard_info) {
        // Update the charts in the copied object
        this.updateChart(
          updatedEventData.standard_info.job_function_chart,
          this.jobfunctionColors
        );
        this.updateChart(
          updatedEventData.standard_info.job_level_chart,
          this.jobLevelColors
        );
        this.updateChart(
          updatedEventData.standard_info.sponsor_industry_chart,
          this.jobfunctionColors
        );
        this.updateChart(
          updatedEventData.standard_info.sponsor_size_chart,
          this.jobLevelColors
        );
      }

      // Apply the batched updates to the global state
      this._eventData.set(updatedEventData);
    }
  }
  private updateChart(chart: JobChart[], colors: string[]): void {
    chart.forEach((item: JobChart, index: number) => {
      item.bgColor = colors[index];
      item.active = false;
    });
  }

  setSponsors(limitItems: boolean = false): void {
    if (this.eventData?.standard_info?.sponsors) {
      const uniqueSponsors = new Set<string>();
      if (this.eventData?.standard_info?.sponsors) {
        this.eventData?.standard_info.sponsors.forEach((sponsor: any) => {
          uniqueSponsors.add(JSON.stringify(sponsor));
        });
      }
      this._sponsorList.set(
        Array.from(uniqueSponsors).map((sponsor: string) => JSON.parse(sponsor))
      );

      const sponsors = this._sponsorList(); // Access the signal's current value
      sponsors.forEach(
        (sponsor: any) => (sponsor.locked = this.userService.isFreeUser())
      );
      this._sponsorList.set(sponsors); // Update the signal with the modified list

      if (limitItems)
        this._shortSponsorList.set(this._sponsorList().slice(0, 4));
    }
  }

  public setActive(
    chartKey: ChartKey,
    item: JobChart,
    action: 'enter' | 'leave',
    index: number,
    colorSet: string[],
    grayscaleColors: string[],
    eventData: IEvent | null
  ): void {
    // Deactivate all items in the chart
    eventData?.standard_info?.[chartKey]?.forEach(
      (entry) => (entry.active = false)
    );

    if (action === 'enter') {
      // Apply grayscale colors to all items
      eventData?.standard_info?.[chartKey]?.forEach(
        (entry: any, i: number) => (entry.bgColor = grayscaleColors[i])
      );
      // Activate the specific item
      item.active = true;
      item.bgColor = colorSet[index];
    } else {
      // Reset colors to the default color set
      eventData?.standard_info?.[chartKey]?.forEach(
        (entry: any, i: number) => (entry.bgColor = colorSet[i])
      );
      // Deactivate the specific item
      item.active = false;
    }
  }
}

export const eventDetailResolver: ResolveFn<IEvent> = (route) => {
  const loaderService = inject(LoadingService);
  loaderService.showLoading('Personalizing event view...');
  return (
    route.queryParams['eid'] &&
    inject(EventsService)
      .getEventFromSlug(route.queryParams['eid'])
      .pipe(
        catchError((error) => {
          inject(ErrorHandlerService).handleError(error);
          return [];
        }),
        finalize(() => loaderService.hideLoading())
      )
  );
};
