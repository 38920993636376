import { inject, Injectable, signal } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { HttpService } from '@http/http.service';
import {
  ISearchProfile,
  ISponsorSearchProfile,
} from '@interfaces/searches.interface';
import { Observable } from 'rxjs/internal/Observable';
import { tap } from 'rxjs/internal/operators/tap';

@Injectable({
  providedIn: 'root',
})
export class SearchesService {
  public savedSearches = signal<ISearchProfile[]>([]);
  public selectedSearch = signal<ISearchProfile | undefined>(undefined);
  public savedSponsorSearches = signal<ISponsorSearchProfile[]>([]);
  public selectedSponsorSearch = signal<ISponsorSearchProfile | undefined>(
    undefined
  );

  constructor(private httpService: HttpService) {}

  public getSavedSearches(): Observable<any> {
    return this.httpService.get(`search_profile/all`).pipe(
      tap((searchResponse: any) => {
        if (searchResponse) {
          this.savedSearches.set(searchResponse.search_profiles);
        }
      })
    );
  }

  public getSavedSponsorSearches(): Observable<any> {
    return this.httpService.get('search_profile/all').pipe(
      tap((savedSponsorSearches: any) => {
        if (savedSponsorSearches) {
          this.savedSponsorSearches.set(
            savedSponsorSearches.company_search_profiles
          );
        }
      })
    );
  }

  public updateSearchProfile(uid: string, payload: any): Observable<any> {
    return this.httpService.put(`search_profile`, payload);
  }

  public deleteSearchProfile(uid: string): Observable<any> {
    return this.httpService.delete('search_profile/' + uid);
  }

  public deleteSponserSearchProfile(uid: string): Observable<any> {
    return this.httpService.delete('company/search_profile/' + uid);
  }
}

export const savedSearchesResolver: ResolveFn<void> = () => {
  return inject(SearchesService).getSavedSearches();
};
