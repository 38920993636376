import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, inject, Injectable, Injector } from '@angular/core';
import { LoadingService } from '@controllers/loading.service';
import { MixPanelService } from '@services/mix-panel.service';
import { ToastService } from '@services/toast/toast.service';
import { LoggingService } from '@utils/logging.service';

export interface MixPanelError {
  api_name: string;
  method: string;
  view: string;
  error: string;
}

@Injectable({ providedIn: 'root' })
export class ErrorHandlerService implements ErrorHandler {
  private readonly mixPanelService = inject(MixPanelService);
  private readonly toastService = inject(ToastService);
  private readonly loadingService = inject(LoadingService);

  constructor(private injector: Injector) {}

  handleError(error: Error | HttpErrorResponse, mixPanel?: MixPanelError) {
    const loggingService = this.injector.get(LoggingService);
    this.loadingService.hideLoading();

    if (error instanceof HttpErrorResponse) {
      // Server or connection error
      console.error('Server error:', error.message);
      this.mixPanelService.trackEvent('API error', { error: error.message });
    } else {
      // Client Error
      console.error('Client error:', error.message);
      this.mixPanelService.trackEvent('client error', { error: error.message });
    }
    // this.toastService.handleError(error.message) add this in later

    // Log the error
    loggingService.logError(error);

    if (mixPanel) this.mixPanel(mixPanel);
  }

  mixPanel(mixPanel: MixPanelError) {
    this.mixPanelService.trackEvent('api_error', mixPanel);
  }
}
