export const environment = {
  name: 'production',
  production: true,
  apiUrl: 'https://prod.coventapi.com',
  version: '1.0.0',
  mixpanelToken: '446dd61652afbb6979e385c4a5ace3f0',
  firebase: {
    apiKey: "AIzaSyBv_xMfspnA2drjhmWnUBfEFjUEgZeBUHs",
    authDomain: "covent-d1487.firebaseapp.com",
    projectId: "covent-d1487",
    storageBucket: "covent-d1487.appspot.com",
    messagingSenderId: "905932431745",
    appId: "1:905932431745:web:055a40c64972d830af4880",
    measurementId: "G-NW75NTLB3Y"
  },
  stripe: {
    publicKey: 'pk_live_51PvdBUHv23jZlobTFT1sTOMYlAxHyaYamKUEIkJjS89jnHnevk4X1W46N9F8CLtWdG2hGQ1x9QAYrieyQlm0rXLf00K402iYHw',
    sponsorTableId: 'prctbl_1R6coIHv23jZlobTsIgZj4hy',
    organizerTableId: 'prctbl_1R6Ev4Hv23jZlobTxP9YCMgW',
  },
  sentry: {
    dsn: 'https://0a657fb553fecc67a3f2f8f949a253cb@o4507486935318528.ingest.us.sentry.io/4507487096406016'
  },
  sponsorTypeForm: 'dCSTQh3g',
  organizerTypeForm: 'aZR9mVZb' // prod
};
