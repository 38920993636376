import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChildFn,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { EventsService } from '@services/events.service';
import { UserService } from '@services/user.service';
import { map } from 'rxjs';

export const sponsorGuard: CanActivateChildFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const userService = inject(UserService);
  const router = inject(Router);

  if (route.data['bypassGuard']) {
    return true; // Bypass guard if data flag is set
  }

  if (userService.isSponsor()) {
    return true;
  } else {
    router.navigate(['/home']);
    return false;
  }
};

export const slugGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) => {
    const router = inject(Router);

    if (route.queryParams['eid']) {
        return true
    } else {
        router.navigate(['/home']);
        return false
    }
  };
