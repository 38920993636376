import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular/standalone';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private readonly cssClass = ['font-semibold', 'text-center', 'text-sm'];

  constructor(private toastController: ToastController) {}

  async present(position: 'top' | 'middle' | 'bottom') {
    const toast = await this.toastController.create({
      message:
        "We've received your request. Someone from our team will reach out to you soon.",
      duration: 1500,
      position: position,
      color: 'primary',
      cssClass: this.cssClass,
    });

    await toast.present();
  }

  async message(position: 'top' | 'middle' | 'bottom', message: string) {
    const toast = await this.toastController.create({
      message,
      duration: 1500,
      position: position,
      color: 'primary',
      cssClass: this.cssClass,
    });

    await toast.present();
  }

  async handleError(errorMessage: string) {
    const toast = await this.toastController.create({
      message: 'There was an error handling your request: ' + errorMessage,
      duration: 1500,
      position: 'bottom',
      color: 'danger',
      cssClass: this.cssClass,
    });

    await toast.present();
  }
}
