import { computed, inject, Injectable, OnDestroy, signal } from '@angular/core';
import { coventDB } from '@dexie/database';
import { ChatRoomBase } from '@dexie/models/chatroombase.model';
import { HttpService } from '@http/http.service';
import {
  ChatMember,
  ChatRoomCreateRequest,
  IChatMessageResponse,
  IChatRoomBase,
} from '@interfaces/chatroombase.interface';
import {
  BehaviorSubject,
  catchError,
  interval,
  Observable,
  of,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { UserService } from './user.service';
import { ResolveFn } from '@angular/router';
import { ErrorHandlerService } from '@http/error-handler.service';

@Injectable({
  providedIn: 'root',
})
export class ChatService implements OnDestroy {
  public readonly userService = inject(UserService);
  public readonly rooms = signal<IChatRoomBase[]>([]);
  public readonly chatRoomMessageList = signal<IChatMessageResponse[]>([]);
  public readonly currentRoom = signal<IChatRoomBase | null>(null);
  public readonly currentMember = signal<ChatMember | any>({
    alias: '',
    subscriber_status: '',
  });
  public readonly unreadRoomMessagesCount = signal<any>({});
  public readonly totalMessagesCount = signal<number>(0);
  public readonly otherUsers = signal<any[]>([]);
  public readonly iAgreeChatRoomPolicy = signal<boolean>(false);
  public readonly showMessageSentCopy = signal(true);
  public readonly socketConnected = signal(false);
  public readonly viewMode = signal<'active' | 'pending'>('active');

  readonly alias = this.userService.currentUserValue.alias;

  readonly organizer =
    this.userService.currentUserValue.user_role === 'organizer';

  private destroy$ = new Subject<void>();
  private pollingData$ = new BehaviorSubject<any>(null);
  private isPolling = false;
  constructor(private httpService: HttpService) {}

  getOtherMember(room: IChatRoomBase) {
    const o = room?.title?.[this.userService.currentUserValue?.alias]?.split('@ ');

    if (o) {
      return {
        name: o[0],
        company: o[1],
      };
    } else {
      return {
        name: '',
        company: '',
      };
    }
  }

  roomStatus = computed(() =>
    this.viewMode() === 'pending' ? 'pending' : 'active'
  );

  readonly blurInfo = computed(
    () =>
      this.currentRoom()?.room_status === 'pending' &&
      !this.userService.isSponsor()
  );

  get chatMembers() {
    return this.currentRoom()?.title?.[this.currentMember().alias]?.split('@ ');
  }

  getUserChatRooms(room_status?: 'pending' | 'active') {
    const baseUrl = 'chat/user/chat_rooms';
    const url = room_status ? `${baseUrl}?room_status=${room_status}` : baseUrl;
    return this.httpService.get<IChatRoomBase[]>(url);
  }

  public getAllChatMessagesOnRoomId(
    chat_room_uid: string,
    offset: number
  ): Observable<any> {
    return this.httpService.get(
      'chat/chat_messages/' + chat_room_uid + '/' + offset
    );
  }

  public setChatRoom(userAlias: string): Observable<any> {
    return this.httpService.get(`chat/chat_room_uid/'${userAlias}'`).pipe(
      tap((chatRoom: any) => {
        if (chatRoom) {
          this.rooms.set([...this.rooms(), chatRoom]);
          this.rooms().unshift(chatRoom);
          this.currentRoom.set(this.rooms()[0]);
          this.currentMember.set(
            this.currentRoom()?.members[this.userService.currentUserValue.alias]
          );
        }
      })
    );
  }

  public getChatRoom(request: ChatRoomCreateRequest) {
    return this.httpService.post<any>('chat/chat_room_uid', request);
  }

  public createChatRoom(request: ChatRoomCreateRequest) {
    return this.httpService.post<any>('chat/', request);
  }

  public async updateChatMessages(message: IChatMessageResponse): Promise<any> {
    const chatMessagesItem: any = await coventDB.chatMessages
      .where('chat_room_uid')
      .equals(message.chat_room_uid)
      .toArray();

    return new Promise(async (resolve, reject) => {
      if (chatMessagesItem?.length) {
        this.chatRoomMessageList.update(
          (chatMessages: IChatMessageResponse[]) => {
            return [...chatMessages, message];
          }
        );
        await coventDB.updateChatMessages(
          chatMessagesItem[0].id,
          this.chatRoomMessageList(),
          message.chat_room_uid
        );
        resolve(true);
      } else {
        reject(true);
      }
    });
  }

  public patchChatRoomStatus(
    chat_room_uid: string,
    room_status: string
  ): Observable<any> {
    return this.httpService.patch(`chat/${chat_room_uid}/${room_status}`, {});
  }

  public getRoomsMessageCount(userAlias: string): Observable<any> {
    return this.httpService
      .get('chat/unread_messages_count/' + userAlias)
      .pipe(
        tap((count: any) => {
          this.unreadRoomMessagesCount.set(count);
          if (this.unreadRoomMessagesCount()) {

            let countNum = 0;
            Object.entries(this.unreadRoomMessagesCount()).forEach(
              ([key, value]) => {
                countNum += value as number;
              }
            );
            this.totalMessagesCount.set(countNum);
            this.rooms().forEach((room) => {
              if (this.unreadRoomMessagesCount()[room.uid]) {
                room.unread_messages_count =
                  this.unreadRoomMessagesCount()[room.uid];
              }
            });

            if (this.totalMessagesCount() > 0) {
              console.log('here in total message count', this.totalMessagesCount())
              this.httpService
                .get('chat/user/chat_rooms')
                .pipe(
                  tap((rooms: any) => {
                    this.rooms().forEach((room: any, index) => {
                      if (room.uid === rooms[index]?.uid) {
                        room.last_message = rooms[index].last_message;
                      }
                    });
                    this.rooms().forEach((room) => {
                      if (this.unreadRoomMessagesCount()[room.uid]) {
                        room.unread_messages_count =
                          this.unreadRoomMessagesCount()[room.uid];
                      }
                    });
                  })
                )
                .subscribe();
            }

            setTimeout(() => {
              this.rooms().sort((a: any, b: any) => {
                return (
                  new Date(a.last_updated_timestamp).getTime() -
                  new Date(b.last_updated_timestamp).getTime()
                );
              });
            });
          }
        }),
        catchError((error) => {
          console.error('API call failed:', error);
          return [];
        })
      )
      .pipe(
        switchMap((response) => {
          this.pollingData$.next(response);
          return [response];
        })
      );
  }

  startUnreadMessagesPolling(userAlias: string): Observable<any> {
    if (!this.isPolling) {
      this.isPolling = true;

      this.getRoomsMessageCount(userAlias);

      interval(60000)
        .pipe(
          takeUntil(this.destroy$),
          switchMap(() => this.getRoomsMessageCount(userAlias))
        )
        .subscribe();
    }

    return this.pollingData$.asObservable();
  }

  stopPolling(): void {
    this.isPolling = false;
    this.destroy$.next();
  }

  setLatestMessage(message: any) {
    this.updateChatMessages(message.chat_message).then(() => {
      // this.scrollToBottom();

      this.currentRoom()!.last_message = message.chat_message;
      this.rooms().sort((a: any, b: any) => {
        return (
          new Date(a.last_updated_timestamp).getTime() -
          new Date(b.last_updated_timestamp).getTime()
        );
      });
    });
  }

  public resetUnredMessagesCount(): void {
    this.currentRoom()!.unread_messages_count = 0;
    if (
      this.totalMessagesCount() > 0 &&
      this.unreadRoomMessagesCount()[this.currentRoom()!.uid] > 0
    ) {
      this.totalMessagesCount.update(
        (totalCount: number) =>
          totalCount - this.unreadRoomMessagesCount()[this.currentRoom()!.uid]
      );
    }
    this.unreadRoomMessagesCount()[this.currentRoom()!.uid] = 0;
  }

  public sendMessage(message: any): Observable<any> {
    return this.httpService.put('chat/chat_message', message);
  }

  ngOnDestroy(): void {
    this.stopPolling();
    this.destroy$.complete();
  }
}

export const getChatRoomsResolver: ResolveFn<IChatRoomBase[]> = () => {
  const errorService = inject(ErrorHandlerService);
  return inject(ChatService)
    .getUserChatRooms()
    .pipe(
      catchError((error) => {
        errorService.handleError(error);
        return of([]);
      })
    );
};
