type KeyValue = { label: string; value: number };

export const JOB_FUNCTIONS: KeyValue[] = [
  { label: 'Bizdev', value: 25 },
  { label: 'Sales', value: 30 },
  { label: 'IT', value: 25 },
  { label: 'Marketing', value: 15 },
  { label: '+3', value: 5 },
];
export const JOB_FUNCTION_COLORS: string[] = [
  '#F6BFB3',
  '#E18D76',
  '#B55A44',
  '#8B3A28',
  '#5E1E12',
];
export const JOB_BAR_COLORS_GRAYSCALE: string[] = [
  '#F5F5F5',
  '#DADADA',
  '#8F8F8F',
  '#5E5E5E',
  '#4A4A4A',
];
export const JOB_LEVELS: KeyValue[] = [
  { label: 'Director', value: 30 },
  { label: 'Manager', value: 10 },
  { label: 'Intern', value: 15 },
  { label: 'C-suite', value: 40 },
  { label: '+2', value: 5 },
];
export const JOB_LEVEL_COLORS: string[] = [
  '#738DEA',
  '#2E55E0',
  '#213C9F',
  '#192F7B',
  '#13245E',
];
