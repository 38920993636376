import { Routes } from '@angular/router';
import { authGuard } from '@guards/auth.guard';
import { homeGuard } from '@guards/home.guard';
import { superAdminGuard } from './guards/super-admin.guard';
import { adminGuard } from './guards/admin.guard';
import { accountSetupGuard, onboardingGuard } from '@guards/onboarding.guard';
import { organizerGuard } from '@guards/organizer.guard';
import { slugGuard, sponsorGuard } from '@guards/sponsor.guard';
import { getChatRoomsResolver } from '@services/chat.service';
import { allEventsResolver } from '@services/events.service';
import { eventDetailResolver } from '@services/events/event-detail.service';
import { homeDataResolver } from '@services/home.service';
import { personaManagerResolver } from '@services/persona/persona.service';
import { savedSearchesResolver } from '@services/searches.service';
import {
  allBookmarkedSponsorshipsResolver,
  getAllSponsorShips,
  getSponsorResolver,
  getSponsorsResolver,
} from '@services/sponsors.service';
import { getUserResolver } from '@services/user.service';

export const sponsorMemberRoutes: Routes = [
  {
    path: 'search',
    loadComponent: () =>
      import('./event-search/event-search.page').then((m) => m.EventSearchPage),
    resolve: { savedSearchesResolver },
  },
  {
    path: 'event-detail',
    loadComponent: () =>
      import('./event-detail/event-detail.page').then((m) => m.EventDetailPage),
    canActivate: [slugGuard],
    resolve: {
      sponsorships: getAllSponsorShips,
      eventDetail: eventDetailResolver,
    },
    data: { bypassGuard: true },
  },
  {
    path: 'persona',
    loadComponent: () =>
      import('./persona/persona.page').then((m) => m.PersonaPage),
    resolve: { personaView: personaManagerResolver },
  },
  {
    path: 'sponsor',
    loadComponent: () =>
      import('./sponsor/sponsor.page').then((m) => m.SponsorPage),
    resolve: { sponsors: getSponsorsResolver },
  },
  {
    path: 'sponsor/:id',
    loadComponent: () =>
      import('./sponsor-edit/sponsor-edit.page').then((m) => m.SponsorEditPage),
    resolve: { sponsor: getSponsorResolver },
  },
  {
    path: 'persona/:id',
    loadComponent: () =>
      import('./create-persona/create-persona.page').then(
        (m) => m.CreatePersonaPage
      ),
  },
  {
    path: 'create-persona',
    loadComponent: () =>
      import('./create-persona/create-persona.page').then(
        (m) => m.CreatePersonaPage
      ),
  },
  {
    path: 'saved-search',
    loadComponent: () =>
      import('./saved-searches/saved-searches.page').then(
        (m) => m.SavedSearchesPage
      ),
  },
  {
    path: 'saved-events',
    loadComponent: () =>
      import('./saved-events/saved-events.page').then((m) => m.SavedEventsPage),
  },
  {
    path: 'feedback',
    loadComponent: () =>
      import('./feedback/feedback.page').then((m) => m.FeedbackPage),
  },
  {
    path: 'event/slug/:id',
    redirectTo: 'home',
    pathMatch: 'full',
  },
];

export const organiserMemberRoutes: Routes = [
  {
    path: 'sponsors',
    loadComponent: () =>
      import('./home-sponsors/home-sponsors.page').then(
        (m) => m.SponsorHomePage
      ),
    canActivate: [homeGuard], // add this back in soon
  },
  {
    path: 'sponsor-search',
    loadComponent: () =>
      import('./sponsor-searches/sponsor-searches.page').then(
        (m) => m.SponsorSearchesPage
      ),
    resolve: { events: allEventsResolver },
  },
  {
    path: 'saved-sponsor',
    loadComponent: () =>
      import('./saved-sponsors/saved-sponsors.page').then(
        (m) => m.SavedSponsorPage
      ),
    resolve: { sponsors: allBookmarkedSponsorshipsResolver },
  },
  {
    path: 'events',
    loadComponent: () =>
      import('./events/events.page').then((m) => m.EventsPage),
    resolve: { events: allEventsResolver },
  },
  {
    path: 'create-event',
    loadComponent: () =>
      import('./create-event/create-event.page').then((m) => m.CreateEventPage),
  },
  {
    path: 'edit-event/:id',
    loadComponent: () =>
      import('./create-event/create-event.page').then((m) => m.CreateEventPage),
  },
  /*{
      path: 'feedback',
      loadComponent: () =>
        import('./feedback/feedback.page').then((m) => m.FeedbackPage),
    } */
];

/* export function getMemberRoutes(): any {
  const userRole = localStorage.getItem('covent.user.role');
  console.log('userRole', userRole);
  if (userRole === 'sponsor') {
    return sponsorMemberRoutes;
  } else if (userRole === 'organizer') {
    return organiserMemberRoutes;
  } else {
    return [];
  }
} */

export const routes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./member/member.page').then((m) => m.MemberPage),
    canActivate: [authGuard],
    resolve: { user: getUserResolver },
    runGuardsAndResolvers: 'always',
    /* children: getMemberRoutes() */
    children: [
      { path: '', redirectTo: '/home', pathMatch: 'full' },
      {
        path: 'home',
        loadComponent: () => import('./home/home.page').then((m) => m.HomePage),
        canActivate: [homeGuard],
        resolve: { homedata: homeDataResolver },
      },
      {
        path: 'dashboard',
        loadComponent: () => import('./dashboard/dashboard.page').then((m) => m.DashboardPage),
        canActivate: [adminGuard],
      },
      {
        path: 'admin',
        loadComponent: () => import('./admin/admin.page').then((m) => m.AdminPage),
        canActivate: [superAdminGuard],
      },
      {
        path: 'chat',
        loadComponent: () => import('./chat/chat.page').then((m) => m.ChatPage),
        resolve: { rooms: getChatRoomsResolver },
      },
      {
        path: '',
        canActivateChild: [organizerGuard],
        children: organiserMemberRoutes,
      },
      {
        path: '',
        canActivateChild: [sponsorGuard],
        children: sponsorMemberRoutes,
      },
    ],
  },
  {
    path: 'pricing',
    loadComponent: () =>
      import('./pricing/pricing.page').then((m) => m.PricingPage),
    canActivate: [onboardingGuard],
  },
  {
    path: 'signin',
    loadComponent: () => import('./login/login.page').then((m) => m.LoginPage),
  },
  {
    path: 'account-setup',
    loadComponent: () =>
      import('./account-setup/account-setup.page').then(
        (m) => m.AccountSetupPage
      ),
    canActivate: [onboardingGuard, accountSetupGuard],
  },
  {
    path: '**',
    redirectTo: '404',
    pathMatch: 'full',
  },
  {
    path: '404',
    loadComponent: () =>
      import('./page-not-found/page-not-found.page').then(
        (m) => m.PageNotFoundPage
      ),
  },
  {
    path: 'sponsor-edit',
    loadComponent: () =>
      import('./sponsor-edit/sponsor-edit.page').then((m) => m.SponsorEditPage),
  },
];
