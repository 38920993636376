import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { UserService } from '@services/user.service';
import { map } from 'rxjs/internal/operators/map';
import { catchError } from 'rxjs/internal/operators/catchError';
import { of } from 'rxjs/internal/observable/of';

export const onboardingGuard: CanActivateFn = (route, state) => {
  const userService = inject(UserService);
  const router = inject(Router);

  return userService.isAuthenticated().pipe(
    map((user: any) => {
      if (user?.email) {
        return true;
      } else {
        router.navigate(['/signin']);
        return false;
      }
    }),
    catchError((error) => {
      router.navigate(['/signin']);
      return of(false);
    })
  );
};

export const accountSetupGuard: CanActivateFn = (route, state) => {
  const userService = inject(UserService);
  const router = inject(Router);
  console.log('set up guard')

  return userService.getUserApi().pipe(
    map((user) => {
      if (
        user.tandc_agreed &&
        user.onboarded &&
        user.account_status === 'active'
      ) {
        console.log(1, user)
        router.navigateByUrl(
          userService.isSponsor() ? '/search' : '/sponsor-search'
        );
        return false;
      } else {
        console.log(2)
        return true;
      }
    }),
    catchError(() => {
        console.log(3)
      router.navigateByUrl('/signin');
      return of(false);
    })
  );
};
