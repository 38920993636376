import { inject, Injectable, Signal, signal } from '@angular/core';
import { coventDB } from '@dexie/database';
import { Events } from '@dexie/models/events.model';
import { User } from '@dexie/models/user.model';
import { HttpService } from '@http/http.service';
import { EventSaveResponse, IEvent } from '@interfaces/events.interface';
import { IEventOrganizer } from '@interfaces/events.org.interface';
import { firstValueFrom } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { tap } from 'rxjs/internal/operators/tap';
import { MixPanelService } from './mix-panel.service';
import { ResolveFn } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  public searchedEvents: Signal<Events[]> = signal([]);
  public events = signal<IEvent[]>([]);
  public savedEvents = signal<IEvent[]>([]);
  public selectedEvent = signal<IEvent | undefined>(undefined);
  public selectedEventType = signal<string>('event');
  public eventHistoryId: Signal<any> = signal(-1);
  public eventHistoryName: Signal<string> = signal('');
  public savedEventOrganizers = signal<IEventOrganizer[]>([]);

  public showEventDetail = signal(false);
  public expandEventDetail = signal(false);
  public initializeEventsDetail = signal(false);
  public eventCreateSuccess = signal<boolean>(false);

  public allAvailableEvents = signal<IEvent[]>([]);
  private mixPanelService = inject(MixPanelService);

  constructor(private httpService: HttpService) {}

  public async getLocalData(): Promise<Events[]> {
    const allEvents: any[] = await coventDB.events.toArray();
    allEvents.sort(
      (a: Events, b: Events) =>
        new Date(b.date).getTime() - new Date(a.date).getTime()
    );
    this.searchedEvents = signal(allEvents);
    if (this.searchedEvents().length) {
      this.events = signal(allEvents[0].events);
      this.eventHistoryId = signal(allEvents[0].id);
      this.eventHistoryName = signal(allEvents[0].name);
    }
    return allEvents;
  }

  public saveLocalData(
    events: IEvent[],
    searchData: { searchName: string; uid: string; query: string }
  ): void {
    coventDB.saveEvents(events, searchData);
    this.getLocalData();
  }

  public async getDataById(id: string): Promise<IEvent[]> {
    const events = await coventDB.events.where('id').equals(id).toArray();
    this.eventHistoryId = signal(id);
    this.eventHistoryName = signal(events[0].name);
    return events[0].events as IEvent[];
  }

  public saveEvent(event: IEvent): Observable<EventSaveResponse | null> {
    return this.httpService
      .post(`event/bookmark/${event.uid}`, {
        name: event.search_profile_name,
        uid: event.search_profile_uid,
      })
      .pipe(
        tap((eventObject: any) => {
          if (this.selectedEvent()) {
            // this.selectedEvent.set({ ...this.selectedEvent(), ...eventObject });
          }
          this.updateEvent(event.uid, eventObject);
          this.mixPanelService.bookmark(event)
        })
      );
  }

  public likeDislikeEvent(
    event: IEvent,
    user: User,
    type: string
  ): Observable<any> {
    let likedFlag: any = type === 'like';
    if (
      (type === 'like' && event.liked_flag === true) ||
      (type === 'dislike' && event.liked_flag === false)
    ) {
      likedFlag = null;
    } else if (type === 'dislike') {
      likedFlag = false;
    }
    return this.httpService
      .post('event/rec_reaction', {
        uid: user.uid,
        event_uid: event.uid,
        /* name: event.search_profile_name, */
        search_profile_uid: event.search_profile_uid,
        liked: likedFlag,
        liked_flag: likedFlag,
        user_alias: user.alias,
      })
      .pipe(
        tap((eventObject: any) => {
          eventObject.liked_flag = likedFlag;
          if (this.selectedEvent()) {
            this.selectedEvent.set({ ...this.selectedEvent(), ...eventObject });
          }
          this.updateEvent(event.uid, eventObject);
        })
      );
  }

  private async updateEvent(
    eventUid: string,
    eventObject: EventSaveResponse
  ): Promise<void> {
    this.events.update((events: IEvent[]) => {
      events.forEach((event: IEvent) => {
        if (eventUid && eventObject && event?.uid === eventUid) {
          event.bookmark_flag = eventObject.bookmark_flag;
          event.liked_flag = !!eventObject.liked_flag;
        } else {
          event.bookmark_flag = false;
        }
      });
      return [...events];
    });
    /* this.savedEvents.update((events: IEvent[]) => {
      events.forEach((event: IEvent) => {
        if (event?.uid === eventUid) {
          event.bookmark_flag = eventObject?.bookmark_flag ? true : false;
          event.liked_flag = eventObject?.liked_flag ? true : false;
        }
      });
      return [...events];
    }); */
    this.getSavedEvents().subscribe();
    coventDB.updateEvents(this.eventHistoryId(), this.events());
  }

  public getSavedEvents(): Observable<any> {
    return this.httpService.get('event/bookmark/all').pipe(
      tap((events: any) => {
        this.savedEvents = signal(events);
      })
    );
  }

  public getEventFromSlug(slug: string): Observable<Partial<IEvent>> {
    return this.httpService.get('event/slug/' + slug);
  }

  public eventSave(payload: any): Observable<any> {
    return this.httpService.put('event', payload).pipe(
      tap((event: any) => {
        if (event.event_status === 'active') {
          this.eventCreateSuccess.set(true);
        }
      })
    );
  }

  public eventPatch(payload: any, uid: string): Observable<any> {
    return this.httpService.patch('event/' + uid, payload).pipe(
      tap((event: any) => {
        if (event.event_status === 'active') {
          this.eventCreateSuccess.set(true);
        }
      })
    );
  }

  public getAllEvents(): Observable<any> {
    return this.httpService.get('event/all/events').pipe(
      tap((events: any) => {
        this.savedEventOrganizers.set(events);
      })
    );
  }

  public async getEventById(uid: string): Promise<any> {
    if (this.savedEventOrganizers().length) {
      return this.savedEventOrganizers().find(
        (event: IEventOrganizer) => event.uid === uid
      );
    } else {
      const events = await firstValueFrom(this.getAllEvents());
      return events.find((event: IEventOrganizer) => event.uid === uid);
    }
  }

  public eventDelete(uid: string): Observable<any> {
    return this.httpService
      .delete('event/' + uid)
      .pipe(tap((response: any) => {}));
  }

  public getEventAIOverview(uid: string): Observable<string> {
    return this.httpService.get(`event/ai_overview/${uid}`);
  }

  public getEvent(uid: any): Observable<any> {
    return this.httpService.get('event/' + uid);
  }

  public getSimilarEvents(uid: string, payload: any): Observable<any> {
    return this.httpService.post(`event/similar_events/${uid}`, payload);
  }
}

export const allEventsResolver: ResolveFn<IEvent[]> = () => {
  return inject(EventsService).getAllEvents()
} 