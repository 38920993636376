export const EVENT_TYPES: {
  icon: string;
  label: string;
  selected?: boolean;
}[] = [
  {
    icon: 'networking',
    label: 'Networking',
  },
  {
    icon: 'lunch',
    label: 'Lunch',
  },
  {
    icon: 'breakfast',
    label: 'Breakfast',
  },
  {
    icon: 'panel',
    label: 'Panel',
  },
  {
    icon: 'conference',
    label: 'Conference',
  },
  {
    icon: 'dinner',
    label: 'Dinner',
  },
  {
    icon: 'expo',
    label: 'Expo',
  },
  {
    icon: 'tradeshow',
    label: 'Tradeshow',
  },
  {
    icon: 'training',
    label: 'Training',
  },
  {
    icon: 'workshop',
    label: 'Workshop',
  },
  {
    icon: 'happyhour',
    label: 'Happy Hour',
  },
  {
    icon: 'awards',
    label: 'Awards',
  },
  {
    icon: 'retreats',
    label: 'Retreats',
  },
  {
    icon: 'partnership',
    label: 'Partnership Opportunity',
  },
  {
    icon: 'executive-summit',
    label: 'Executive Summit',
  },
];

export const AUDIENCE_LIST: string[] = [
  'Accounting',
  'Administrative',
  'Arts and Design',
  'Business Development',
  'Community and Social Services',
  'Consulting',
  'Education',
  'Engineering',
  'Entrepreneurship',
  'Finance',
  'Healthcare Services',
  'Human Resources',
  'Information Technology',
  'Legal',
  'Marketing',
  'Media and Communications',
  'Military and Protective Services',
  'Operations',
  'Product Management',
  'Program and Project Management',
  'Purchasing',
  'Quality Assurance',
  'Real Estate',
  'Research',
  'Sales',
  'Support',
  'Training',
  'Writing and Editing',
];

export const INDUSTRIES: string[] = [
  'Technology & SaaS',
  'Healthcare',
  'Finance',
  'Education',
  'Retail & E-commerce',
  'Consumer Goods',
  'Media & Entertainment',
  'Travel & Hospitality',
  'Automotive',
  'Energy & Sustainability',
  'Real Estate',
  'Food & Beverage',
  'Government & Nonprofit',
  'Logistics & Supply Chain',
  'Sports & Fitness',
  'Artificial Intelligence (AI)',
];

export const countryList: string[] = [
  'United States',
  'Canada',
  'Brazil',
  'Mexico',
  'Argentina',
  'Brazil',
  'Mexico',
  'Argentina',
  'Colombia',
  'Chile',
  'Germany',
  'United Kingdom',
  'France',
  'Italy',
  'Russia',
  'Saudi Arabia',
  'United Arab Emirates',
  'Egypt',
  'Iran',
  'Turkey',
  'India',
  'Indonesia',
  'New Zealand',
  'Singapore',
  'Thailand',
  'Viet Nam',
  'Australia',
];

export const COUNTRY_CITY_DATA: { [country: string]: string[] } = {
  'United States': ['New York', 'Los Angeles', 'Chicago', 'Houston', 'Phoenix'],
  'United Kingdom': [
    'London',
    'Birmingham',
    'Glasgow',
    'Liverpool',
    'Manchester',
  ],
  Germany: ['Berlin', 'Hamburg', 'Munich', 'Cologne', 'Frankfurt'],
  France: ['Paris', 'Marseille', 'Lyon', 'Toulouse', 'Nice'],
  Australia: ['Sydney', 'Melbourne', 'Brisbane', 'Perth', 'Adelaide'],
  Japan: ['Tokyo', 'Osaka', 'Yokohama', 'Nagoya', 'Sapporo'],
  Canada: ['Burnaby'],
  China: ['Shanghai', 'Beijing', 'Guangzhou', 'Shenzhen', 'Chongqing'],
  India: ['Mumbai', 'Delhi', 'Hyderabad', 'Bangalore', 'Chennai'],
  Indonesia: ['Jakarta', 'Surabaya', 'Bandung', 'Semarang', 'Medan'],
  'New Zealand': ['Wellington', 'Aucklan'],
  Brazil: ['Sao Paulo', 'Rio de Janeiro', 'Salvador', 'Brasilia', 'Fortaleza'],
  Mexico: ['Mexico City', 'Guadalajara', 'Monterrey', 'Puebla', 'Tijuana'],
  Colombia: ['Bogota', 'Medellin', ',Cali', 'Barranquilla', 'Cartagena'],
  Chile: ['Santiago', 'Valparaiso', 'Concepcion', 'La Serena', 'Antofagasta'],
  Russia: [
    'Moscow',
    'Saint Petersburg',
    'Novosibirsk',
    'Yekaterinburg',
    'Nizhny Novgorod',
  ],
  'Saudi Arabia': ['Riyadh', 'Jeddah', 'Mecca', 'Medina', 'Dammam'],
  'United Arab Emirates': ['Dubai', 'Abu Dhabi', ' Sharjah', 'Al Ain', 'Ajman'],
  Egypt: ['Cairo', 'Alexandria', 'Giza', 'Sharm El Sheikh', 'Luxor'],
  Italy: ['Rome', 'Milan', 'Florence', 'Venice', 'Naples'],
  Turkey: ['Istanbul', 'Ankara', 'Izmir', 'Antalya', 'Bursa'],
  Singapore: [
    'Singapore City',
    'Jurong East',
    'Bedok',
    'Woodlands',
    'Tampines',
  ],
  Thailand: ['Bangkok', 'Chiang Mai', 'Pattaya', 'Phuket City', 'Ayutthaya'],
  Vietnam: ['Ho Chi Minh City', 'Hanoi', 'Da Nang', 'Hai Phong', 'Can Tho'],
  Iran: ['Tehran', 'Mashhad', 'Esfahan', 'Tabriz', 'Shiraz'],
  Argentina: ['Buenos Aires', 'Cordoba', 'Rosario', 'Mendoza', 'La Plata'],
};

export const REACH_OPTIONS: {
  value: string;
  label: string;
  reachFrom: number;
  reachTo: number | null;
}[] = [
  {
    value: '10-50',
    label: '10 - 50',
    reachFrom: 10,
    reachTo: 50,
  },
  {
    value: '51-100',
    label: '51 - 100',
    reachFrom: 51,
    reachTo: 100,
  },
  {
    value: '101-200',
    label: '101 - 200',
    reachFrom: 101,
    reachTo: 200,
  },
  {
    value: '201-500',
    label: '201 - 500',
    reachFrom: 201,
    reachTo: 500,
  },
  {
    value: '501-1000',
    label: '501 - 1000',
    reachFrom: 501,
    reachTo: 1000,
  },
  {
    value: '1001-5000',
    label: '1,000 - 5,000',
    reachFrom: 1001,
    reachTo: 5000,
  },
  {
    value: '5000+',
    label: '5,000+',
    reachFrom: 5001,
    reachTo: null,
  },
];
