<ion-app>
  <ion-router-outlet id="main-content" [animated]="isNativeApp ? true : false"></ion-router-outlet>
</ion-app>

<ion-modal class="update-checker-modal" id="updateCheckerModal">
  <ng-template>
    <ion-header>
      <ion-toolbar>
        <ion-title class="text-xl">Update available!</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <p class="leading-tight mx-6 text-base font-medium my-2">CoVent has new update available.</p>
      <p class="m-0 leading-tight mx-6">Updating the app will get latest features, enhancements and bug fixes.</p>
    </ion-content>
    <ion-footer class="pr-2">
      <ion-toolbar>
        <ion-buttons slot="primary">
          <ion-button fill="solid" class="part-native:px-4 text-sm font-medium" (click)="applyUpdate()">
            Update
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-footer>
  </ng-template>
</ion-modal>