import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { UserService } from '@services/user.service';

export const adminGuard: CanActivateFn = (route, state) => {
  const userService = inject(UserService);
  const router = inject(Router);

  // Allow both admin and super_admin users
  if (userService.isAdmin() || userService.isSuperAdmin()) {
    return true;
  }

  // Redirect to home if not an admin or super_admin
  return router.parseUrl('/home');
};
