import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { defineCustomElement as defineLoading } from '@ionic/core/components/ion-loading';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  public loading!: HTMLIonLoadingElement | null;

  constructor(private loadingCtrl: LoadingController) {
    defineLoading();
  }

  async showLoading(message: string = 'Loading..') {
    if(this.loading)
      this.hideLoading();
    
    this.loading = await this.loadingCtrl.create({
      message: message,
      spinner: 'crescent',
      translucent: false,
      showBackdrop: true,
      cssClass: 'covent-loading',
    });
    setTimeout(() => this.hideLoading(), 10000);
    this.loading.present();
  }

  async hideLoading() {
    if(this.loading) {
      this.loading.dismiss();
      this.loading = null;
    }
  }
}
