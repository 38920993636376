import { inject } from '@angular/core';
import { CanActivateChildFn, Router } from '@angular/router';
import { UserService } from '@services/user.service';

export const organizerGuard: CanActivateChildFn = (childRoute, state) => {
  const userService = inject(UserService);
  const router = inject(Router);

  if (userService.isSponsor()) {
    router.navigate(['/home']);
    return false;
  } else {
    return true;
  }
};
